import { graphql } from "gatsby";
import React from "react";

import DefaultLayout from "../components/layouts/DefaultLayout";
import SEO from "../components/layouts/SEO";
import Hero from "../components/StaticPage/Hero";
import PricingList from "../components/PricingPage/PricingList";
import useBoolean from "../hooks/useBoolean";
import PricingSwitch from "../components/PricingPage/PricingSwitch";

const Pricing: React.FC = () => {
  const [isMonthly, setMonthly] = useBoolean(true);

  return (
    <DefaultLayout>
      <SEO
        title="Pricing | Ensemble"
        url="https://ensembleai.io/about"
        desc="we help enterprise organisations build and run advanced data, analytics and AI capabilities based on modern cloud-native technology."
        ogImage="https://ensembleai.io/assets/img/home.png"
      />
      <Hero
        title="Pricing"
        description="Membership Options"
        className="lg:pt-[134px] lg:pb-[137px] pb-10 pt-[96px]"
      >
        <div className="flex justify-center pt-8">
          <PricingSwitch isMonthly={isMonthly} onChange={setMonthly.toggle} />
        </div>
      </Hero>
      <PricingList isMonthly={isMonthly} />
    </DefaultLayout>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`;

export default Pricing;
