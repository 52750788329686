import classNames from "classnames";
import React from "react";
import Switch from "../UI/Switch";

interface IPricingSwitchProps {
  isMonthly: boolean;
  onChange: () => void;
}

const PricingSwitch: React.FC<IPricingSwitchProps> = ({
  isMonthly,
  onChange,
}) => {
  return (
    <div className="inline-flex gap-3 items-center">
      <span
        className={classNames("text-white text-base font-semibold transition-opacity", {
          "opacity-20": !isMonthly,
        })}
      >
        Monthly
      </span>
      <Switch isEnabled={!isMonthly} onChange={onChange} />
      <span
        className={classNames("text-white text-base font-semibold transition-opacity", {
          "opacity-20": isMonthly,
        })}
      >
        Annually
      </span>
    </div>
  );
};

export default PricingSwitch;
